import { useNotice } from '@ucheba/ui/components/Notice/bll'
import { useCallback, useMemo, useState } from 'react'
import { stringifyUrl } from '@ucheba/utils/helpers/router'
import { useSelector } from 'react-redux'
import { siteNameSelectors } from '@ucheba/store/siteName'
import { useRouter } from 'next/router'
import { isBrowser } from '@ucheba/utils/helpers/core'

interface IUseRegDialog {
  (): {
    isOpen: boolean
    onClose: () => void
    onOpen: (data?: Record<string, string | number>) => void
    params: Record<string, string | number>
  }
}

export const useRegDialog: IUseRegDialog = () => {
  const dialog = useNotice('regDialogId')
  const [params, setParams] = useState<Record<string, string | number>>({})

  const onOpen = useCallback(
    (data?: Record<string, string | number>) => {
      if (data) {
        setParams(data)
      } else {
        setParams({})
      }

      dialog.addNotice()
    },
    [dialog]
  )

  return {
    isOpen: !!dialog.open,
    onClose: dialog.removeNotice,
    onOpen,
    params,
  }
}

interface IUseMessengersHref {
  (params: Record<string, string | number>): {
    vkHref: string
    tgHref: string
    waHref: string
  }
}

export const useMessengersHref: IUseMessengersHref = (params) => {
  const siteName = useSelector(siteNameSelectors.siteName)

  const domain = useMemo(() => {
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (isBrowser) {
      if (window.location.host.includes('school')) {
        return process.env.NEXT_PUBLIC_URL as string
      }
    }

    return ''
  }, [isBrowser])

  const vkHref = useMemo(() => {
    return stringifyUrl(`${domain}/events/vk-subscribe/registration`, {
      update: {
        ...params,
        siteName,
      },
    })
  }, [params, siteName])

  const tgHref = useMemo(() => {
    return stringifyUrl(`${domain}/events/tg-subscribe/registration`, {
      update: {
        ...params,
        siteName,
      },
    })
  }, [params, siteName])

  const waHref = useMemo(() => {
    return stringifyUrl(`${domain}/events/whatsapp-subscribe/registration`, {
      update: {
        ...params,
        siteName,
      },
    })
  }, [params, siteName])

  return {
    vkHref,
    tgHref,
    waHref,
  }
}
